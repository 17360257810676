import React, { PureComponent, Suspense, lazy } from 'react'
import classNames from 'clsx'
import PropTypes from 'prop-types'
// UI组件
import { Layout } from 'antd'
// 自定义组件
import PageLoading from '../PageLoading'
import { getDefaultCollapsedSubMenus } from './SiderMenuUtils'
// 样式部分
import styles from './index.module.less'
const BaseMenu = lazy(() => import('./BaseMenu'))

const { Sider } = Layout

class SidebarMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openKeys: getDefaultCollapsedSubMenus(props)
    }
  }
  static getDerivedStateFromProps(props, state) {
    const { pathname } = state
    // eslint-disable-next-line react/prop-types
    if (props.location.pathname !== pathname) {
      return {
        // eslint-disable-next-line react/prop-types
        pathname: props.location.pathname,
        openKeys: getDefaultCollapsedSubMenus(props)
      }
    }
    return null
  }

  isMainMenu = key => {
    const { menuData } = this.props
    return menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key
      }
      return false
    })
  }

  handleOpenChange = openKeys => {
    const moreThanOne =
      openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys]
    })
  }

  render() {
    const { collapsed, onCollapse, fixSiderbar, navTheme } = this.props
    const { openKeys } = this.state
    const defaultProps = collapsed ? {} : { openKeys }

    const siderClassName = classNames(styles.sider, {
      [styles.fixSiderbar]: fixSiderbar,
      [styles.light]: navTheme === 'light'
    })
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
        width={204}
        theme={navTheme}
        className={siderClassName}
      >
        <Suspense fallback={<PageLoading />}>
          <BaseMenu
            {...this.props}
            mode="inline"
            handleOpenChange={this.handleOpenChange}
            onOpenChange={this.handleOpenChange}
            style={{ padding: '16px 0', width: '100%' }}
            {...defaultProps}
          />
        </Suspense>
      </Sider>
    )
  }
}
SidebarMenu.propTypes = {
  location: PropTypes.object,
  breadcrumbNameMap: PropTypes.object,
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  routes: PropTypes.array,
  navTheme: PropTypes.oneOf(['light', 'dark']),
  layout: PropTypes.string,
  menuData: PropTypes.array,
  fixSiderbar: PropTypes.bool,
  onCollapse: PropTypes.func
}

export default SidebarMenu
