import { configure } from 'mobx'
import RoutersStore from './routers'
import SettingStore from './setting'
import ModuleStore from './module'
import UserStore from './user'
import SubstanceStore from './substance'
import MutualStore from './mutual'
import Education from './education'

// 不允许在动作之外进行状态修改
configure({ enforceActions: 'observed' })

class RootStore {
  constructor() {
    this.routers = new RoutersStore(this)
    this.setting = new SettingStore(this)
    this.modules = new ModuleStore(this)
    this.user = new UserStore(this)
    this.substance = new SubstanceStore(this)
    this.mutual = new MutualStore(this)
    this.education = new Education(this)
  }
}

export default RootStore
