import React from 'react'
import PropTypes from 'prop-types'
// 自定义组件
import SidebarMenu from './SidebarMenu'
import { getFlatMenuKeys } from './SiderMenuUtils'

// eslint-disable-next-line react/display-name
const SiderMenuWrapper = React.memo(props => {
  const { menuData } = props
  const flatMenuKeys = getFlatMenuKeys(menuData)
  return <SidebarMenu {...props} flatMenuKeys={flatMenuKeys} />
})

SiderMenuWrapper.propTypes = {
  menuData: PropTypes.array
}
export default SiderMenuWrapper
