import React from 'react'
import { Provider } from 'mobx-react'
import Routers from '../Routers'
import RootStore from '../Store'
import { ConfigProvider } from 'antd'
import { Beforeunload } from 'react-beforeunload'
import moment from 'moment'
import 'moment/locale/zh-cn'
import zhCN from 'antd/es/locale/zh_CN'
// 样式
import styles from './App.module.less'

moment.locale('zh-cn')

const clearLocalCache = () => {
  window.sessionStorage.clear()
}

function App() {
  return (
    <Beforeunload onBeforeunload={clearLocalCache}>
      <ConfigProvider locale={zhCN}>
        <div className={styles.App}>
          <Provider {...new RootStore()}>
            <Routers />
          </Provider>
        </div>
      </ConfigProvider>
    </Beforeunload>
  )
}
export default App
