import {
  action,
  computed,
  flow,
  observable
} from 'mobx'
import {
  getQueryStrings
} from '../utils'
import {
  getClasses,
  getTokenValue,
  getMenusByToken,
  getLoginToken,
  getAccessToken
} from '../services/api'
import {
  message
} from 'antd'

class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable queryInfo = sessionStorage.getItem('queryInfo') ?
    JSON.parse(sessionStorage.getItem('queryInfo')) :
    ''
  @observable currentUser = sessionStorage.getItem('currentUser') ?
    JSON.parse(sessionStorage.getItem('currentUser')) :
    {}
  @observable state = 'pending'

  @observable classesAndGrades = sessionStorage.getItem('classesAndGrades') ?
    JSON.parse(sessionStorage.getItem('classesAndGrades')) :
    []

  @observable clickKey = '56'

  // action
  @computed get gradeLists () {
    return Object.keys(this.classesAndGrades)
  }

  @action.bound
  setClickKey = values => {
    this.clickKey = values
  }
  @action.bound
  fetchTokenValue = flow(function* (dataToken) {
    const self = this
    const queryInfo = getQueryStrings()
    if (queryInfo.action == 'xjj') {
      this.getLoginToken(queryInfo.code)
    } else {
      let token = dataToken || self.queryInfo.token || queryInfo.token
      self.state = 'pending'
      self.queryInfo = getQueryStrings()
      // sessionStorage.setItem('queryInfo', JSON.stringify(queryInfo))
      sessionStorage.setItem(
        'queryInfo',
        JSON.stringify(getQueryStrings())
      )

      // self.currentUser = {
      //   "bindStatus": 0,
      //   "cityId": 0,
      //   "countyId": 610117,
      //   "functionType": 2,
      //   "gradeYearId": 0,
      //   "indexUrl": "policy-interpret",
      //   "isInit": 0,
      //   "isManager": 0,
      //   "isScoreManager": 1,
      //   "isSuperManager": "1",
      //   "login4chenzhi": 1,
      //   "meuns": [{
      //     "meunId": 10,
      //     "meunName": "智能云屏",
      //     "meunUrl": "/classCard",
      //     "sonMeuns": [{
      //       "meunId": 56,
      //       "meunName": "风采模式",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 59,
      //       "meunName": "横幅模式",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 60,
      //       "meunName": "考场模式",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 61,
      //       "meunName": "倒计时",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 62,
      //       "meunName": "风采展示",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 65,
      //       "meunName": "通知公告",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 68,
      //       "meunName": "班级荣誉",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 84,
      //       "meunName": "班牌管理",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 86,
      //       "meunName": "素材库",
      //       "meunUrl": "",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 101,
      //       "meunName": "班级概况",
      //       "meunUrl": "",
      //       "urlType": 1
      //     }
      //     ],
      //     "urlType": 1
      //   },
      //   {
      //     "iconUrl": "icon-sidebar-6",
      //     "meunId": 18,
      //     "meunName": "基础信息设置",
      //     "meunUrl": "./views/pages/homeMenuPages/baseInfoManege.html",
      //     "sonMeuns": [{
      //       "iconUrl": "nianjiguanli.png",
      //       "meunId": 19,
      //       "meunName": "年级管理",
      //       "meunUrl": "/basicInfoManagement/grade-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "kechengguanli.png",
      //       "meunId": 21,
      //       "meunName": "课程管理",
      //       "meunUrl": "/basicInfoManagement/course-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "jiaoshiguanli.png",
      //       "meunId": 24,
      //       "meunName": "教师管理",
      //       "meunUrl": "/basicInfoManagement/teacher-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "xueshengguanli.png",
      //       "meunId": 25,
      //       "meunName": "学生管理",
      //       "meunUrl": "/basicInfoManagement/student-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "chengjiguanli.png",
      //       "meunId": 29,
      //       "meunName": "基础成绩",
      //       "meunUrl": "/scoreManage-teacher",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "chengjiguanli.png",
      //       "meunId": 30,
      //       "meunName": "教学时间管理",
      //       "meunUrl": "/basicInfoManagement/teach-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "chengjiguanli.png",
      //       "meunId": 34,
      //       "meunName": "教室管理",
      //       "meunUrl": "/basicInfoManagement/classroom-management",
      //       "urlType": 1
      //     },
      //     {
      //       "iconUrl": "chengjiguanli.png",
      //       "meunId": 35,
      //       "meunName": "课表管理",
      //       "meunUrl": "/basicInfoManagement/timetable-management",
      //       "urlType": 1
      //     }
      //     ],
      //     "urlType": 1
      //   },
      //   {
      //     "iconUrl": "icon-sidebar-7",
      //     "meunId": 26,
      //     "meunName": "权限管理",
      //     "meunUrl": "/accountInfoManagement/role-management",
      //     "sonMeuns": [],
      //     "urlType": 1
      //   },
      //   {
      //     "meunId": 133,
      //     "meunName": "考勤管理",
      //     "meunUrl": "",
      //     "sonMeuns": [{
      //       "meunId": 131,
      //       "meunName": "教师考勤",
      //       "meunUrl": "/teacherAttendance",
      //       "urlType": 1
      //     },
      //     {
      //       "meunId": 132,
      //       "meunName": "学生考勤",
      //       "meunUrl": "/studentAttendance",
      //       "urlType": 1
      //     }
      //     ],
      //     "urlType": 1
      //   }
      //   ],
      //   "platformType": 1,
      //   "provinceId": 610000,
      //   "roles": [
      //     "管理员"
      //   ],
      //   "schoolFrom": "WXWORK",
      //   "schoolStatus": 0,
      //   "standardSchoolType": 9,
      //   "status": 0,
      //   "studentStatus": 0,
      //   "teacherCode": getQueryStrings().teacherCode,
      //   "tnAddr": "陕西省西安市高新区",
      //   "tnId": getQueryStrings().tnId,
      //   "tnName": "高德松测试学校",
      //   "tnType": 1,
      //   "type": 0,
      //   "userAccount": "18220808080",
      //   "userId": getQueryStrings().userId,
      //   "userName": "管理员",
      //   "userType": 1,
      //   "year": 0,
      //   "token": getQueryStrings().token,
      //   "from": "undefined"
      // }
      sessionStorage.setItem(
        'currentUser',
        JSON.stringify(self.currentUser)
      )
      try {

        const response = yield getTokenValue({
          accountToken: token
        })
        if (response.data.retCode === 1) {
          const bizData = response.data.data

          // self.currentUser = {
          //   ...bizData
          // }
          self.currentUser.userType = bizData.userType
          self.currentUser.schoolCode = bizData.schoolCode
          self.currentUser.schoolId = String(bizData.schoolId)
          self.currentUser.schoolName = bizData.schoolName
          self.currentUser.userCode = bizData.userCode
          self.currentUser.userName = bizData.userName
          self.currentUser.tnId = String(bizData.schoolId)
          self.currentUser.teacherCode = bizData.userCode
          const queryInfo = getQueryStrings()
          queryInfo.tnId = String(bizData.schoolId)
          queryInfo.teacherCode = bizData.userCode
          queryInfo.userId = bizData.userId
          self.queryInfo = queryInfo
          sessionStorage.setItem('queryInfo', JSON.stringify(queryInfo))
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify({ ...bizData, ...queryInfo })
          )
          self.state = 'done'
        }
      } catch (error) {
        self.state = 'error'
        console.log('21121')
      }
    }
  })

  getLoginToken = flow(function* (code) {
    const self = this
    sessionStorage.setItem('source', 'XJJYP')
    self.state = 'pending'

    try {
      // 1=选分排应用 2=智能云屏应用
      var data = {
        client: 'XJJCZ',
        auth: {
          code: code,
          type: 2
        }
      }
      const result = yield getLoginToken(data)
      if (result.data.retCode == 1) {
        const res = result.data.retContent
        const token = res && res.list && res.list[0] && res.list[0].token
        this.getMenusByToken(token)
      } else {
        message.error(response.data.retMsg)
      }
    } catch (error) {
      self.state = 'error'
    }
  })

  getMenusByToken = flow(function* (token) {
    const self = this
    try {
      // 1=选分排应用 2=智能云屏应用
      const response = yield getMenusByToken({
        token: token,
        type: 2
      })

      if (response.data.retCode == 1) {
        const {
          retContent
        } = response.data
        self.currentUser = {
          ...retContent
        }
        let queryInfo = {
          tnId: retContent.tnId,
          token: retContent.token,
          userId: retContent.userId
        }
        self.queryInfo = queryInfo
        sessionStorage.setItem('queryInfo', JSON.stringify(queryInfo))
        sessionStorage.setItem(
          'currentUser',
          JSON.stringify({
            ...retContent,
            ...queryInfo
          })
        )
        self.state = 'done'
        const params = {
          schoolId: retContent.tnId,
          teacherCode: retContent.teacherCode,
          type: 1
        }
        console.log(params, 'params')
        // this.rootStore.routers.fetchUserResource(params)
      }
    } catch (error) {
      self.state = 'error'
    }
  })

  @action.bound
  fetchGetClassesAndGrades = flow(function* (callback) {
    let self = this
    let tnId = self.currentUser.tnId
    if (!tnId) return false
    try {
      const response = yield getClasses({
        schoolId: tnId
      })
      if (response.data.retCode === 1) {
        const data = response.data.data ? response.data.data : []
        self.classesAndGrades = data
        sessionStorage.setItem('classesAndGrades', JSON.stringify(data))
        if (callback) callback()
      } else {
        message.error(response.data.retMsg)
      }
    } catch (e) {
      console.log(e)
    }
  })

  // ! 这个地方进行换token的操作，主要是因为210需要进行拆分出去
  //! 去获取tocken
  getTocken = async (phone, schoolId) => {
    let self = this
    const params = {
      schoolId: schoolId,
      telPhone: phone
    }
    try {
      const res = await getAccessToken(params)
      if (res.data.rtnCode == '0000000') {
        const tocken = res.data.bizData
        self.fetchTokenValue(tocken)
      } else {
        console.log(res.data.message)
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export default UserStore
