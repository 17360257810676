import { observable } from 'mobx'
const DefaultSettings = {
  navTheme: 'light',
  primaryColor: '#1890FF',
  layout: 'sidemenu',
  contentWidth: 'Fluid',
  autoHideHeader: true,
  fixSiderbar: false,
  colorWeak: false,
  menu: {
    locale: true
  },
  title: '辰知',
  pwa: false,
  iconfontUrl: ''
}
class SettingStore {
  @observable settingInfo = DefaultSettings
  constructor(rootStore) {
    this.rootStore = rootStore
  }
}

export default SettingStore
