import { action, flow, observable } from 'mobx'
import { getGradesAndClasses } from '../services/api'
import { message } from 'antd'

class MutualStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable gradeInfoLists = []
  @observable classInfoLists = []

  // action
  @action.bound
  fetchGradeLists = flow(function*() {
    const current = this
    const currentUser = current.rootStore.user.currentUser
    try {
      const response = yield getGradesAndClasses({
        schoolId: currentUser.tnId,
        type: 1,
        code: ''
      })
      if (response.data.retCode === 1) {
        current.gradeInfoLists = response.data.data.list || []
      } else {
        message.error(response.data.retMsg)
      }
    } catch (error) {
      console.log(error)
    }
  })

  @action.bound
  fetchClassLists = flow(function*(code) {
    const current = this
    const currentUser = current.rootStore.user.currentUser
    try {
      const response = yield getGradesAndClasses({
        schoolId: currentUser.tnId,
        code: code,
        type: 2
      })
      if (response.data.retCode === 1) {
        current.classInfoLists = response.data.data.list || []
      } else {
        message.error(response.data.retMsg)
      }
    } catch (error) {
      console.log(error)
    }
  })
}

export default MutualStore
