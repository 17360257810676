/* eslint-disable */
import { action, flow, observable } from 'mobx'
import { queryTeacherAuthority, queryTermWeek } from '../services/api'
import { message } from 'antd'
import { timeToMonthAndDay, digitalConversion } from '../utils/index'

class Education {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable teacherAuthority = []
  @observable termWeekList = []
  @observable currTime = {}

  // action
  @action.bound
  fetchTeacherAuthority = flow(function*() {
    const current = this
    const currentUser = current.rootStore.user.currentUser
    console.log(currentUser,'currentUser')
    try {
      const response = yield queryTeacherAuthority({
        schoolId: currentUser.tnId,
        teacherCode: currentUser.teacherCode
      })
      if (response.data.retCode == 1) {
        current.teacherAuthority = response.data.data.list || []
      } else {
        message.error(response.data.retMsg)
      }
    } catch (error) {
      console.log(error)
    }
  })

  @action.bound
  fetchTermWeek = flow(function*() {
    const current = this
    const currentUser = current.rootStore.user.currentUser
    try {
      const response = yield queryTermWeek({
        schoolId: currentUser.tnId
      })
      if (response.data.retCode == 1) {
        // 在这里进行一次数据的转换，将原有的数据添加一个字段增加到数组里面
        const currNum = response.data.data.currNum
        const list = response.data.data.termWeek
        list.forEach(item => {
          item.weekTime =
            timeToMonthAndDay(item.startTime) +
            '~' +
            timeToMonthAndDay(item.endTime) +
            ' 第' +
            digitalConversion(item.num) +
            '周'
          if (item.num == currNum) {
            current.currTime = item
          }
        })

        current.termWeekList = list || []
        // current.currTime = currNum
      } else {
        message.error(response.data.retMsg)
      }
    } catch (error) {
      console.log(error)
    }
  })
}

export default Education
