// import Qs from 'qs'
import fetchRequest from './fetch'
import fetchRequests from './fetchs'

/*
 * getTokenValue 解析Token
 * */
export const getTokenValue = tokenValue => {
  return fetchRequest({
    url: '/banpai/base/queryUserInfoByAccountToken',
    method: 'get',
    params: tokenValue
  })
}

/*
 * getMenusByToken 根据token获取菜单信息
 * */
export const getMenusByToken = params => {
  return fetchRequests({
    url: '/TeacherWechat/auth/getMenusByToken',
    method: 'get',
    params: params
  })
}

/*
 * getMenusByToken 查询用户可见的功能
 * */
export const queryUserResource = params => {
  return fetchRequests({
    url: '/base/resource/queryUserResource',
    method: 'get',
    params: params
  })
}

/*
 * getMenusByToken 根据token获取菜单信息
 * */
export const getLoginToken = data => {
  return fetchRequests({
    url: '/TeacherWechat/auth/login',
    method: 'post',
    data: data
  })
}
/*
 * 基于手机号换tocken
 *
 */

export const getAccessToken = queryInfo => {
  return fetchRequests({
    url: '/base/uc/service/getAccessToken',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取年级和班级
 * */
export const getClasses = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/getClasses',
    method: 'get',
    params: queryInfo
  })
}
// /*
//  * 创建分组/更新分组
//  * */
// export const saveOrUpdateGroup = groupInfo => {
//   return fetchRequest({
//     url: '/banpai/fc/group/saveOrUpdateGroup',
//     method: 'post',
//     groupInfo
//   })
// }
/*
 * 获取风采
 * */

export const getGroup = groupDetails => {
  return fetchRequest({
    url: '/banpai/fc/group/getGroup',
    method: 'post',
    data: groupDetails
  })
}
/*
 * 上传风采
 * */

export const uploadElegant = filesInfo => {
  return fetchRequest({
    url: '/banpai/fc/uplaodFc',
    method: 'post',
    data: filesInfo
  })
}

/*
 *  删除风采
 * */

export const deleteElegant = elegant => {
  return fetchRequest({
    url: '/banpai/fc/deleteFc',
    method: 'get',
    params: elegant
  })
}

/*
 *  发布风采
 * */

export const releaseElegant = elegantInfo => {
  return fetchRequest({
    url: '/banpai/show/release',
    method: 'post',
    data: elegantInfo
  })
}

/*
 * 获取横幅模式操作记录
 * */

export const getStreamerRecords = query => {
  return fetchRequest({
    url: '/banpai/show/getRecord',
    method: 'get',
    params: query
  })
}
/*
 * 删除风采发布记录
 * */
export const deleteStreamerRecord = queryId => {
  return fetchRequest({
    url: '/banpai/show/deleteRecord',
    method: 'get',
    params: {
      id: queryId
    }
  })
}
/*
 * 获取教室
 * */

export const getRoomsInfo = query => {
  return fetchRequest({
    url: '/banpai/base/getRooms',
    method: 'get',
    params: query
  })
}


/***
 *
 * 绑定教室
 *
*/

export const bindRoom = query => {
  return fetchRequest({
    url: '/banpai/api/device/service/bindRoom',
    method: 'get',
    params: query
  })
}


/*
 * 获取考场
 * */

export const getExaminationLists = gradeInfo => {
  return fetchRequest({
    url: '/banpai/exam/getExamRooms',
    method: 'post',
    data: gradeInfo
  })
}

/*
 * 上传班牌logo
 * */

export const uploadClassLogo = paramInfo => {
  return fetchRequest({
    url: '/banpai/api/class/uploadLogo',
    method: 'post',
    data: paramInfo
  })
}

/*
 * 保存班级首屏的轮播图分组
 * */

export const shufflingFigure = groupInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassShowGroup',
    method: 'post',
    data: groupInfo
  })
}

/*
 * 获取班级扩展信息/班训，轮播图，logo等
 * */

export const getClassExtendedInfo = classInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassExtInfo',
    method: 'post',
    data: classInfo
  })
}

/*
 * 保存班训
 * */
export const saveClassExtended = classData => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassMotto',
    method: 'post',
    data: classData
  })
}
/*
 * 获取学校课程
 * */
export const getSchoolCourse = schoolId => {
  return fetchRequest({
    url: '/banpai/base/getCourses',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}
/*
 * 获取全校教师
 * */

export const getTeachersLists = schoolId => {
  return fetchRequest({
    url: '/banpai/base/getTeachers',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}

/*
 * 新增/更新考场
 * */

export const addOrModifyExam = formData => {
  return fetchRequest({
    url: '/banpai/exam/saveOrUpdateExam',
    method: 'post',
    data: formData
  })
}
/*
 * 批量/单个修改考场状态
 * */
export const openOrCloseExamInfo = examInfo => {
  return fetchRequest({
    url: '/banpai/exam/openOrCloseExam',
    method: 'post',
    data: examInfo
  })
}
/*
 * 考场通知到班牌
 * */

export const sendExamNotice = id => {
  return fetchRequest({
    url: '/banpai/exam/sendExamNotice',
    method: 'get',
    params: {
      schoolId: id
    }
  })
}

/*
 * 删除考场
 * */

export const deleteExam = examInfo => {
  return fetchRequest({
    url: '/banpai/exam/deleteExam',
    method: 'post',
    data: examInfo
  })
}

/*
 * 获取学生签到考场
 * */

export const getExamSelectRooms = paramsId => {
  return fetchRequest({
    url: '/banpai/exam/getExamSelectRooms',
    method: 'get',
    params: paramsId
  })
}

/*
 * 查询通知
 * */

export const inquireNotice = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/findNotice',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 保存通知
 * */

export const saveNotice = noticeInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/saveOrUpdateNotice',
    method: 'post',
    data: noticeInfo
  })
}

/*
 * 删除通知
 * */

export const deleteNotice = noticeId => {
  return fetchRequest({
    url: '/banpai/api/notice/deleteNotice',
    method: 'get',
    params: {
      id: noticeId
    }
  })
}

/*
 * 上传通知图片
 * */

export const uploadNoticePic = fileLists => {
  return fetchRequest({
    url: '/banpai/api/notice/uplaodNoticePic',
    method: 'post',
    data: fileLists
  })
}

/*
 * 上传通知图片
 * */

export const uploadStudentHeader = fileLists => {
  return fetchRequest({
    url: '/banpai/api/device/service/uploadStudentHeader',
    method: 'post',
    data: fileLists
  })
}

/*
 * 获取班级光荣榜单
 * */

export const getHonorRollLists = query => {
  return fetchRequest({
    url: '/banpai/api/class/findClassHonors',
    method: 'get',
    params: query
  })
}

/*
 * 批量上榜
 * */

export const batchSettingTitle = studentInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassHonors',
    method: 'post',
    data: studentInfo
  })
}

// /*
//  *  批量下榜
//  * */

// export const deleteHonors = studentInfo => {
//   return fetchRequest({
//     url: '/banpai/api/class/deleteHonors',
//     method: 'post',
//     data: studentInfo
//   })
// }

/*
 * 获取考场学生
 * */

export const getExamStudents = queryInfo => {
  return fetchRequest({
    url: '/banpai/exam/getExamStudents',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取学生信息
 * */

export const getStudentsInfoLists = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/getStudents',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 添加/修改考场学生
 * */
export const saveOrUpdateExamStudent = dataInfo => {
  return fetchRequest({
    url: '/banpai/exam/saveOrUpdateExamStudent',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 删除考场学生
 * */

export const deleteExamStudent = deleteId => {
  return fetchRequest({
    url: '/banpai/exam/deleteExamStudent',
    method: 'get',
    params: {
      id: deleteId
    }
  })
}

/*
 * 上传考场学生
 * */
export const uploadExamStudents = studentsInfo => {
  return fetchRequest({
    url: '/banpai/exam/uploadExamStudents',
    method: 'post',
    data: studentsInfo
  })
}

/*
 * 上传考场
 * */
export const uploadExamination = examinationInfo => {
  return fetchRequest({
    url: '/banpai/exam/uploadExam',
    method: 'post',
    data: examinationInfo
  })
}
/*
 * 下载考场学生模版
 * */

export const downloadTemplate = tempNum => {
  return fetchRequest({
    url: '/banpai/base/getTemplate',
    method: 'get',
    params: {
      templateNum: tempNum
    }
  })
}

/*
 * 获取已有通知设置
 * */

export const getNoticeLists = schoolInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/web/queryNotice',
    method: 'get',
    params: schoolInfo
  })
}

/*
 * 开启/关闭考勤通知
 * */
export const updateNoticeSwitch = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/web/updateNoticeSwitch',
    method: 'get',
    params: queryInfo
  })
}
/*
 * 获取通知模板
 * */

export const getNoticeTemplate = id => {
  return fetchRequest({
    url: '/banpai/api/attend/web/queryNoticeTemplate',
    method: 'get',
    params: {
      schoolId: id
    }
  })
}

/*
 * 新增通知设置
 * */

export const addNoticeInfo = dataInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/web/addNotice',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 删除已有通知设置
 * */
export const deleteCurrentNotice = currentId => {
  return fetchRequest({
    url: '/banpai/api/attend/web/delNotice',
    method: 'get',
    params: {
      id: currentId
    }
  })
}

/*
 * WEB - 更新通知设置
 * */

export const updateCurrentNotice = noticeInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/web/updateNotice',
    method: 'post',
    data: noticeInfo
  })
}

/*
 * 查询年级及班级选项
 * */

export const getGradesAndClasses = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/family/phone/querySelectors',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 根据条件查询亲情电话列表
 * */

export const getKinsfolkLists = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/family/phone/queryFamilyPhone',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 批量导入亲情电话
 * */

export const uploadFamilyPhoneList = paramInfo => {
  return fetchRequest({
    url: '/banpai/api/family/phone/uploadFamilyPhone',
    method: 'post',
    data: paramInfo
  })
}

/*
 * WEB - 删除学生亲情电话数据
 * */

export const delCurrentFamilyPhone = currentInfo => {
  return fetchRequest({
    url: '/banpai/api/family/phone/delFamilyPhone',
    method: 'post',
    data: currentInfo
  })
}

/*
 * 查询班牌定时开关机
 * */

export const getDeviceSettingLists = dataInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/getDeviceSetting',
    method: 'post',
    data: dataInfo
  })
}

// 班级查询

export const getNoBindRoom = schoolId => {
  return fetchRequest({
    url: '/banpai/api/device/service/getNoBindRoom',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}

/*
 * 批量开启，关闭定时开关机
 * */

export const openOrCloseSetting = dataInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/openOrCloseSetting',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 保存定时开关机
 * */
export const saveDeviceSetting = dataInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/saveDeviceSetting',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 查询已设置的考勤时段
 * */

export const queryPeriod = schoolId => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryPeriod',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}

/*
 * 设置考勤时段
 * */

export const savePeriod = timeInfos => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/savePeriod',
    method: 'post',
    data: timeInfos
  })
}
/*
 * 查询考勤通知设置模板
 * @param string
 * */

export const queryNoticeTemplate = schoolId => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryNoticeTemplate',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}
/*
 * 获取已有通知设置
 * */

export const getQueryNotice = schoolId => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryNotice',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}

/*
 * 删除已有通知设置
 * */

export const delNoticeConfig = id => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/delNoticeConfig',
    method: 'get',
    params: {
      id: id
    }
  })
}

/*
 * 新增考勤通知设置
 * */

export const addAttendanceNotice = noticeInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/addNotice',
    method: 'post',
    data: noticeInfo
  })
}

/*
 * 获取考勤统计及明细的筛选条件
 * */

export const getQueryCondition = (id = '') => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryCondition',
    method: 'get',
    params: {
      schoolId: id
    }
  })
}

/*
 * 获取教师考勤统计
 * */

export const getQueryStatistics = statistics => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryStatistics',
    method: 'post',
    data: statistics
  })
}

/*
 *
 * */

export const getAttendDetailsInfo = dataInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/teacher/queryAttendDetails',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 获取考场模式 - 学生签到 - 考场统计
 * */

export const getStaticExamAttendance = studentsInfo => {
  return fetchRequest({
    url: '/banpai/exam/staticExamAttendance',
    method: 'post',
    data: studentsInfo
  })
}

/*
 * 获取考场模式 - 学生签到 - 班级统计 -获取行政班接口
 * */

export const getAdministrativeClasses = schoolId => {
  return fetchRequest({
    url: 'banpai/base/getXzClasses',
    method: 'get',
    params: {
      schoolId: schoolId
    }
  })
}

/*
 * 获取考场模式 - 学生签到 - 班级统计
 * staticExamStudentAttendance
 * */

export const staticExamStudentAttendance = dataInfo => {
  return fetchRequest({
    url: 'banpai/exam/staticExamStudentAttendance',
    method: 'post',
    data: dataInfo
  })
}

/*
 * 考勤管理-学生考勤-查询考勤明细的筛选条件
 * */

export const queryCondition = (id = '') => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryCondition',
    method: 'get',
    params: {
      schoolId: id
    }
  })
}

/*
 * 考勤管理-学生考勤-考勤统计
 * */

export const queryStatistic = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryStatistic',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 考勤管理-学生考勤-查询学生考勤明细
 * */

export const queryStudentsDetails = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/attend/web/queryDetails',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 内容管理-通知公告-查询学校在用校牌
 * */

export const querySchoolBoard = (school = '') => {
  return fetchRequest({
    url: 'banpai/api/notice/querySchoolBoard',
    method: 'get',
    params: {
      schoolId: school
    }
  })
}

/*
 * 内容管理-通知公告-查询学校在用校牌
 * */

export const addShow = queryInfo => {
  return fetchRequest({
    url: 'banpai/show/addShow',
    method: 'post',
    data: queryInfo
  })
}

/*
 * /-/-上传阅读资源
 * */

export const uploadRead = queryInfo => {
  return fetchRequest({
    url: 'http://service.91chenzhi.cn/banpai/api/reading/web/upload',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 内容管理-学校概况-上传富文本图片
 * */

export const uploadEdit = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/web/upload',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 内容管理-学校概况-新增概况
 * */

export const saveInfoEdit = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/web/schoolboard/saveInfo',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 内容管理-学校概况-获取概况列表
 * */

export const getSituation = schoolId => {
  return fetchRequest({
    url: 'banpai/api/web/schoolboard/queryInfo',
    method: 'get',
    params: schoolId
  })
}
/*
 * 内容管理-学校概况-删除概况
 * */

export const delSituation = schoolId => {
  return fetchRequest({
    url: 'banpai/api/web/schoolboard/delInfo',
    method: 'get',
    params: schoolId
  })
}
/*
 * 内容管理-班级德育-保存体系
 * */
export const saveDefineInfo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/saveDefineInfo',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 内容管理-班级德育-获取旗帜
 * */
export const queryAvailableAward = schoolId => {
  return fetchRequest({
    url: '/banpai/api/moral/queryAvailableAward',
    method: 'get',
    params: schoolId
  })
}

/*
 * 内容管理-班级德育-删除多余旗帜
 * */
export const deleteAvailableAward = schoolId => {
  return fetchRequest({
    url: '/banpai/api/moral/deleteAvailableAward',
    method: 'get',
    params: schoolId
  })
}
/*
 * 内容管理-班级德育-模糊搜索老师
 * */
// http://testservice.91chenzhi.cn/saas/manageCourse/getTeacherList.do?schoolId=613&teacherName=%E6%9D%8E&token=5b0ad4d3-8805-4e2d-b62c-b624810f87ef
export const getTeacherList = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/getTeacherList',
    method: 'get',
    params: queryInfo
  })
}
/*
 * 班级德育-评分明细-获取老师评分权限
 * */

export const queryTeacherAuthority = teacherInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/queryTeacherAuthority',
    method: 'post',
    data: teacherInfo
  })
}

/*
 * 班级德育-评分明细-登记评分
 * */

export const submitRatingScore = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/submitRatingScore',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 班级德育-评分体系-获取体系
 * */
export const queryMoralDefine = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/queryMoralDefine',
    method: 'get',
    params: queryInfo
  })
}
/*
 * 班级德育-修改指标-保存指标
 * */
export const saveMoralDefineRule = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/saveMoralDefineRule',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 班级德育-修改指标-获取指标
 * */
export const queryMoralDefineRule = moralCode => {
  return fetchRequest({
    url: 'banpai/api/moral/queryMoralDefineRule',
    method: 'get',
    params: moralCode
  })
}
/*
 * 班级德育-修改体系-上传旗帜
 * */
export const upload = (schoolId, file) => {
  return fetchRequest({
    url: 'banpai/api/web/upload',
    method: 'post',
    params: { schoolId },
    data: { file }
  })
}

/*
 * 班级德育-修改体系-保存旗帜
 * */
export const saveAwardUrl = queryInfo => {
  return fetchRequest({
    url: 'banpai/api/moral/saveAwardUrl',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 查询年级
 * */
export const getGrade = queryInfo => {
  return fetchRequests({
    url: '/base/grade/getGrade',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 查询年级班级
 * */
export const getClassList = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/getClassList',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 查询班级列表
 * */
export const queryClassByEnrolYear = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryClassByEnrolYear',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取周次
 * */

export const queryTermWeek = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryTermWeek',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取学校的评分明细
 * */

export const queryScoreItemList = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryScoreItemList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取班级评分信息
 * */

export const queryClassScoreDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryClassScoreDetail',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取学校德育评比统计结果
 * */

export const querySchoolMoralStatistic = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/querySchoolMoralStatistic',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取德育体系下教师可评分的班级
 * */

export const queryMoralClassByTeacher = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryMoralClassByTeacher',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取德育体系班级的学生列表
 * */

export const queryClassStudents = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassStudents',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 获取班级本学期德育评比统计
 * */

export const queryClassMoralStatistic = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/queryClassMoralStatistic',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 取消班级评比资格/撤销决定
 * */

export const submitClassCancel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/submitClassCancel',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 取消评分登记/撤销决定
 * */

export const submitRatingScoreCancel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/submitRatingScoreCancel',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 导出评分明细
 * */

export const exportScoreItemList = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/exportScoreItemList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-获取文件上传授权
 * */

export const createOSSPolicy = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/createOSSPolicy',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-查询用户内容库空间使用情况
 * */

export const querySchoolCapacity = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/querySchoolCapacity',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-保存文件信息
 * */

export const saveFileInfo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/saveFileInfo',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-检查文件名是否重复
 * */

export const checkFileName = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/checkFileName',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-保存课堂内容
 * */

export const saveClassCourse = queryInfo => {
  return fetchRequest({
    url: '/banpai/onlineClass/saveClassCourse',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-查询课堂内容列表
 * */

export const queryClassCourseList = queryInfo => {
  return fetchRequest({
    url: '/banpai/onlineClass/queryClassCourseList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-查询内容文件列表
 * */

export const queryFileList = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/queryFileList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-查询内容文件列表
 * */

export const delSchoolFile = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/delSchoolFile',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-查询课堂内容详细信息
 * */

export const queryClassCourseDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/onlineClass/queryClassCourseDetail',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 在线课堂-查询课堂内容详细信息
 * */

export const publishClassCourse = queryInfo => {
  return fetchRequest({
    url: '/banpai/onlineClass/publishClassCourse',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-更新课程状态
 * */

export const updateCourseStatus = queryInfo => {
  return fetchRequest({
    url: '/banpai/onlineClass/updateCourseStatus',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 在线课堂-获取加密后的accessKey
 * */

export const getStamp = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/getStamp',
    method: 'get',
    params: queryInfo
  })
}

//=========================新增接口=====================

/*
 * 风采模式-查询风采列表
 * */

export const getRecordList = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/getRecordList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-发布风采
 * */

export const release = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/release',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采模式-获取单独的详情
 * */

export const getRecordDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/getRecordDetail',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采模式-删除风采
 * */

export const deleteRecordByCode = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/deleteRecordByCode',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采模式-验证是否覆盖或者重叠
 * */

export const checkFc = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/checkFc',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 通知公告-查询列表
 * */

export const findNoticeList = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/findNoticeList',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 通知公告-保存修改
 * */

export const insertOrUpdateNotice = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/insertOrUpdateNotice',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 通知公告-查询详情
 * */

export const findNoticeDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/findNoticeDetail',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 通知公告-查询详情
 * */

export const deleteNoticeByCode = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/notice/deleteNoticeByCode',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-获取风采相册
 * */

export const queryGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/queryGroup',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采相册-保存风采相册
 * */

export const saveOrUpdateGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/saveOrUpdateGroup',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采相册-获取风采相册内容
 * */

export const queryContentByGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/queryContentByGroup',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-相册置顶
 * */

export const toppingGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/toppingGroup',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 风采相册-删除风采相册
 * */

export const deleteGroup = queryInfo => {
  return fetchRequest({
    url: '/banpai/fc/group/deleteGroup',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 风采相册-获取风采生效班级
 * */

export const getClassesStatus = queryInfo => {
  return fetchRequest({
    url: '/banpai/show/getClassesStatus',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 光荣榜-获取荣誉列表
 * */

export const queryHonors = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryHonors',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 光荣榜-保存修改删除荣誉设置
 * */

export const saveHonors = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveHonors',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 光荣榜-获取上榜和未上榜学生信息
 * */

export const findClassHonors = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/findClassHonors',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 光荣榜-设置上榜学生
 * */

export const saveClassHonors = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassHonors',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 光荣榜-设置上榜学生
 * */

export const deleteHonors = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/deleteHonors',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 光荣榜-根据学生获取荣誉
 * */

export const queryHonorsByStudent = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryHonorsByStudent',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 班级概况-查询班训、logo和学生头像信息
 * */

export const queryClassExtInfo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassExtInfo',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级概况-设置班训
 * */

export const saveClassMotto = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassMotto',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级概况-保存班级logo
 * */

export const saveClassLogo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassLogo',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班级概况-获取学校logo
 * */

export const querySchoolLogo = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/querySchoolLogo',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班牌设置-保存
 * */

export const saveDeviceLock = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/saveDeviceLock',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班牌设置-查询某一笔记录
 * */

export const getDeviceLock = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/getDeviceLock',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 班牌设置-进入班牌详情
 * */

export const getDeviceLockDetail = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/device/service/getDeviceLockDetail',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 内容库-进入班牌详情
 * */

export const queryLabel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/queryLabel',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 标签设置-保存标签
 * */

export const saveLabel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/saveLabel',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 标签设置-删除标签
 * */

export const deleteLabel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/deleteLabel',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 标签设置-删除标签
 * */

export const updateFileLabel = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/updateFileLabel',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 标签设置-批量从文件上删除某个标签
 * */

export const deleteLabelFiles = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/deleteLabelFiles',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 标签设置-重命名文件
 * */

export const renameFile = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/repo/renameFile',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 权限-查询教师可见的班级
 * */

export const queryClassByTeacher = queryInfo => {
  return fetchRequest({
    url: '/banpai/base/queryClassByTeacher',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 家校互通-获取全校的通话时长
 * */

export const countSchoolTotalCallDuration = queryInfo => {
  return fetchRequest({
    url: '/banpai/im/service/countSchoolTotalCallDuration',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 家校互通-统计学校的通话记录
 * */

export const countSchoolCallDuration = queryInfo => {
  return fetchRequest({
    url: '/banpai/im/service/countClassCallDuration',
    method: 'post',
    data: queryInfo
  })
}
/*
 * 家校互通-统计学生的通话记录
 * */

export const countStudentCallDuration = queryInfo => {
  return fetchRequest({
    url: '/banpai/im/service/countStudentCallDuration',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 家校互通-查询学生打卡离校
 * */

export const queryLeaveRecord = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/leaveSchool/queryLeaveRecord',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 家校互通-查询设置的离校打卡时间
 * */

export const queryTimeSet = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/leaveSchool/queryTimeSet',
    method: 'get',
    params: queryInfo
  })
}

/*
 * 家校互通-设置离校打卡时间
 * */

export const saveLeaveTime = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/attend/leaveSchool/saveLeaveTime',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班卡管理-获取班级卡号信息
 * */

export const queryClassCardNum = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/queryClassCardNum',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班卡管理-保存修改和删除班级卡号
 * */

export const saveClassCardNum = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/saveClassCardNum',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 班卡管理-下载班卡数据
 * */

export const downloadClassCardData = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/downloadClassCardData',
    method: 'get',
    params: queryInfo,
    responseType: 'blob'
  })
}

/*
 * 班卡管理-下载班卡上传模板
 * */

export const downloadCardTemplate = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/class/downloadCardTemplate',
    method: 'get',
    params: queryInfo,
    responseType: 'blob'
  })
}

/*
 * 获取学校本周德育排名
 * */

export const querySchoolMoralWeekRank = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/gather/queryWeekRank',
    method: 'post',
    data: queryInfo
  })
}

/*
 * 获取学校德语排名变化
 * */

export const querySchoolMoralWeekScoreChange = queryInfo => {
  return fetchRequest({
    url: '/banpai/api/moral/gather/queryWeekScoreChange',
    method: 'post',
    data: queryInfo
  })
}
