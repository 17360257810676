import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import moment from 'moment'
import styles from './Tv.module.less'
import {
  querySchoolMoralWeekRank,
  querySchoolMoralWeekScoreChange
} from '../../../../services/api'

const rootRef = React.createRef()

@inject(({ user }) => ({
  currentUser: user.currentUser
  // currTime: education.currTime
}))
@observer
class Tv extends Component {
  static propTypes = {
    openNewView: PropTypes.func,
    currentUser: PropTypes.object
    // currTime: PropTypes.num
  }
  constructor(props) {
    super(props)
    this.state = {
      isFullScreen: false,
      total: 0, // 分页中条数
      pageSize: 10, // 分页每页显示条数
      pageNum: 1, // 分页当前页
      collapsed: true, // 是否收起
      visible: false, // 添加、修改弹框
      table: [],
      one: {},
      two: {},
      thr: {},
      four: {},
      fiv: {},
      six: {},
      sev: {},
      eig: {},
      nin: {},
      ten: {},
      ele: {},
      twl: {},
      thrn: {},
      forn: {},
      fif: {},
      sixn: {},
      sevn: {},
      eign: {},
      tabledata: {}
    }
  }

  componentDidMount() {
    // 监听页面全屏事件
    window.onresize = () => {
      // 全屏
      if (document.fullscreenElement) {
        this.setState({ isFullScreen: true })
      }
      // 不是全屏
      else {
        this.setState({ isFullScreen: false })
      }
    }
    this.getSchoolWeekRank()
    this.getSchoolWeekScoreChange()
    setInterval(() => {
      this.getSchoolWeekRank()
      this.getSchoolWeekScoreChange()
    }, 5 * 60 * 1000)
  }

  // 全屏
  fullScreen = () => {
    if (!this.state.isFullScreen) {
      const elm = rootRef.current
      if (elm.requestFullscreen) {
        elm.requestFullscreen()
      } else if (elm.webkitRequestFullscreen) {
        elm.webkitRequestFullscreen()
      } else if (elm.mozRequestFullScreen) {
        elm.mozRequestFullScreen()
      } else if (elm.msRequestFullscreen) {
        elm.msRequestFullscreen()
      }
    }
  }

  // 退出全屏
  exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  getSchoolWeekRank = async () => {
    // ****************这里改动schoolId和currTime***************看怎么获取，获取到分还是秒*********************
    const currTime = parseInt(new Date().getTime().toString())
    const { currentUser } = this.props
    const body = { schoolId: currentUser.tnId, currTime: currTime }

    // const body = { schoolId: 1770, currTime: 1623232517670 }

    const weekRankRes = await querySchoolMoralWeekRank(body)
    // console.log(weekRankRes.data.data.list)
    this.setState({ one: weekRankRes.data.data.list[0] || '' })
    this.setState({ two: weekRankRes.data.data.list[1] || '' })
    this.setState({ thr: weekRankRes.data.data.list[2] || '' })
    this.setState({ four: weekRankRes.data.data.list[3] || '' })
    this.setState({ fiv: weekRankRes.data.data.list[4] || '' })
    this.setState({ six: weekRankRes.data.data.list[5] || '' })
    this.setState({ sev: weekRankRes.data.data.list[6] || '' })
    this.setState({ eig: weekRankRes.data.data.list[7] || '' })
    this.setState({ nin: weekRankRes.data.data.list[8] || '' })
    this.setState({ ten: weekRankRes.data.data.list[9] || '' })
    this.setState({ ele: weekRankRes.data.data.list[10] || '' })
    this.setState({ twl: weekRankRes.data.data.list[11] || '' })
    this.setState({ thrn: weekRankRes.data.data.list[12] || '' })
    this.setState({ forn: weekRankRes.data.data.list[13] || '' })
    this.setState({ fif: weekRankRes.data.data.list[14] || '' })
    this.setState({ sixn: weekRankRes.data.data.list[15] || '' })
    this.setState({ sevn: weekRankRes.data.data.list[16] || '' })
    this.setState({ eign: weekRankRes.data.data.list[17] || '' })
    // return weekRankRes.data
  }

  getSchoolWeekScoreChange = async () => {
    // const data = { schoolId: 1770, currTime: 1623232517670 }

    const currTime = parseInt(new Date().getTime().toString())
    const { currentUser } = this.props
    const data = { schoolId: currentUser.tnId, currTime: currTime }

    const weekRankScoreRes = await querySchoolMoralWeekScoreChange(data)
    // console.log(weekRankScoreRes.data.data.list)
    const table = []
    for (let i = 0; i < weekRankScoreRes.data.data.list.length; i++) {
      const timeN = moment(
        weekRankScoreRes.data.data.list[i].changeTime
      ).format('YYYY-MM-DD HH:mm')
      const fen = Math.abs(weekRankScoreRes.data.data.list[i].score)
      table[i] = [
        '<span style="font-size:21.5pt">&bull; ' + timeN + '</span>',
        '<span style="font-size:21.5pt">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp' +
          weekRankScoreRes.data.data.list[i].className +
          '</span>',
        '<span style="font-size:21.5pt;color:#fcff00">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp【' +
          weekRankScoreRes.data.data.list[i].moralRule +
          '】</span>',
        '<span style="font-size:21.5pt">&nbsp&nbsp扣</span>',
        '<span style="font-size:21.5pt;color:#fcff00">' + fen + '</span>',
        '<span style="font-size:21.5pt">分</span>'
      ]
    }
    this.setState({
      tabledata: {
        data: table,
        index: false,
        columnWidth: ['37%', '28%', '30%'],
        align: ['center'],
        oddRowBGC: ['transparent'],
        evenRowBGC: ['transparent'],
        rowNum: [10]
      }
    })
    // return weekRankScoreRes.data
  }

  render() {
    const {
      tabledata,
      one,
      two,
      thr,
      four,
      fiv,
      six,
      sev,
      eig,
      nin,
      ten,
      ele,
      twl,
      thrn,
      forn,
      fif,
      sixn,
      sevn,
      eign
    } = this.state
    const { isFullScreen } = this.state
    return (
      <div ref={rootRef} className={styles.Tv}>
        <div className={styles.bigtitle}>
          {/* 全屏的时候显示 退出全屏按钮，非全屏的时候显示 全屏按钮 */}
          {isFullScreen ? (
            <button
              onClick={this.exitFullScreen}
              className={styles.btn}
            ></button>
          ) : (
            <button onClick={this.fullScreen} className={styles.btn}>
              全屏
            </button>
          )}
          <span>吉祥路小学值周评比大数据</span>
          <div className={styles.btnO}></div>
        </div>

        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.title}>
              <div className={styles.imgleft}></div>
              <div className={styles.text}>本周排行TOP18</div>
              <div className={styles.imgright}></div>
            </div>
            <div className={styles.score}>
              <div className={styles.scorel}>
                <div className={styles.item}>
                  <div>排名</div>
                  <div>班级名称</div>
                  <div>得分</div>
                </div>
                <div className={styles.item} style={{ color: '#fcff00' }}>
                  <div>
                    <img
                      src={require('../../../../Common/images/1@3x.png')}
                      style={{ height: '80%', width: '50%', marginTop: '-6%' }}
                    />
                  </div>
                  <div>{one.className}</div>
                  <div>{one.score}</div>
                </div>
                <div className={styles.item} style={{ color: '#fcff00' }}>
                  <div>
                    <img
                      src={require('../../../../Common/images/2@3x.png')}
                      style={{ height: '80%', width: '50%', marginTop: '-6%' }}
                    />
                  </div>
                  <div>{two.className}</div>
                  <div>{two.score}</div>
                </div>
                <div className={styles.item} style={{ color: '#fcff00' }}>
                  <div>
                    <img
                      src={require('../../../../Common/images/3@3x.png')}
                      style={{ height: '80%', width: '50%', marginTop: '-6%' }}
                    />
                  </div>
                  <div>{thr.className}</div>
                  <div>{thr.score}</div>
                </div>
                <div className={styles.item}>
                  <div>4</div>
                  <div>{four.className}</div>
                  <div>{four.score}</div>
                </div>
                <div className={styles.item}>
                  <div>5</div>
                  <div>{fiv.className}</div>
                  <div>{fiv.score}</div>
                </div>
                <div className={styles.item}>
                  <div>6</div>
                  <div>{six.className}</div>
                  <div>{six.score}</div>
                </div>
                <div className={styles.item}>
                  <div>7</div>
                  <div>{sev.className}</div>
                  <div>{sev.score}</div>
                </div>
                <div className={styles.item}>
                  <div>8</div>
                  <div>{eig.className}</div>
                  <div>{eig.score}</div>
                </div>
                <div className={styles.item}>
                  <div>9</div>
                  <div>{nin.className}</div>
                  <div>{nin.score}</div>
                </div>
              </div>
              <div className={styles.scorem}></div>
              <div className={styles.scorer}>
                <div className={styles.item}>
                  <div>排名</div>
                  <div>班级名称</div>
                  <div>得分</div>
                </div>
                <div className={styles.item}>
                  <div>10</div>
                  <div>{ten.className}</div>
                  <div>{ten.score}</div>
                </div>
                <div className={styles.item}>
                  <div>11</div>
                  <div>{ele.className}</div>
                  <div>{ele.score}</div>
                </div>
                <div className={styles.item}>
                  <div>12</div>
                  <div>{twl.className}</div>
                  <div>{twl.score}</div>
                </div>
                <div className={styles.item}>
                  <div>13</div>
                  <div>{thrn.className}</div>
                  <div>{thrn.score}</div>
                </div>
                <div className={styles.item}>
                  <div>14</div>
                  <div>{forn.className}</div>
                  <div>{forn.score}</div>
                </div>
                <div className={styles.item}>
                  <div>15</div>
                  <div>{fif.className}</div>
                  <div>{fif.score}</div>
                </div>
                <div className={styles.item}>
                  <div>16</div>
                  <div>{sixn.className}</div>
                  <div>{sixn.score}</div>
                </div>
                <div className={styles.item}>
                  <div>17</div>
                  <div>{sevn.className}</div>
                  <div>{sevn.score}</div>
                </div>
                <div className={styles.item}>
                  <div>18</div>
                  <div>{eign.className}</div>
                  <div>{eign.score}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.title}>
              <div className={styles.imgleft}></div>
              <div className={styles.text}>本周扣分记录</div>
              <div className={styles.imgright}></div>
            </div>
            <div
              style={{
                paddingLeft: '2%',
                paddingRight: '2%',
                marginTop: '-1.5%',
                textAlign: 'center'
              }}
            >
              <ScrollBoard
                config={tabledata}
                style={{ width: '100%', height: '898px' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Tv.propTypes = {
  tabs: PropTypes.array,
  active: PropTypes.string
}

export default Tv
