import { action, flow, observable, computed } from 'mobx'
import { cloneDeep, flatMapDeep, filter } from 'lodash'
import {
  getRoomsInfo,
  getSchoolCourse,
  getTeachersLists
} from '../services/api'
import { message } from 'antd'
import { getQueryStrings } from '../utils'

class ModuleStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }
  // observable
  @observable currentGradeToClass = []
  @observable checkedLists = sessionStorage.getItem('checkedLists')
    ? JSON.parse(sessionStorage.getItem('checkedLists'))
    : [] // 已选中风采
  @observable roomsInfo =
    sessionStorage.roomsInfo && sessionStorage.roomsInfo !== undefined
      ? JSON.parse(sessionStorage.getItem('roomsInfo'))
      : {}
  @observable teachersList = []
  @observable courseList = []
  // computed
  @computed get roomsLists() {
    return filter(
      flatMapDeep(this.roomsInfo, item => [...item]),
      current => current.roomCode !== ''
    )
  }
  // action
  @action
  setClass = newValue => {
    this.currentGradeToClass = newValue
  }
  @action
  setCheckedList = value => {
    let checkLists = cloneDeep(this.checkedLists)
    let isInArray = checkLists.find(
      item => item.resourceCode === value.resourceCode
    )
    if (isInArray) {
      const result = checkLists.filter(
        item => item.resourceCode !== value.resourceCode
      )
      this.checkedLists = result
      sessionStorage.setItem('checkedLists', JSON.stringify(result))
    } else {
      checkLists.push({ ...value })
      this.checkedLists = checkLists
      sessionStorage.setItem('checkedLists', JSON.stringify(checkLists))
    }
  }

  @action.bound
  fetchGetRoomsInfo = flow(function*(callBack) {
    let self = this
    let { tnId } = self.rootStore.user.currentUser
    try {
      const response = yield getRoomsInfo({ schoolId: tnId })
      if (response.data.retCode === 1) {
        const result = response.data.data ? response.data.data : {}
        self.roomsInfo = result
        sessionStorage.setItem('roomsInfo', JSON.stringify(result))
        if (callBack) callBack(result)
      } else {
        message.error(response.data.retMsg)
      }
    } catch (e) {
      console.log(e)
    }
  })

  @action.bound
  fetchGetSchoolCourse = flow(function*() {
    const queryInfo = getQueryStrings()
    const schoolId = queryInfo['tnId']
    let self = this
    try {
      const response = yield getSchoolCourse(schoolId)
      if (response.data.retCode === 1) {
        self.courseList = response.data.data || []
      } else {
        message.error(response.data.retMsg)
      }
    } catch (e) {
      console.log(e)
    }
  })

  @action.bound
  fetchGetSchoolTeacherLists = flow(function*() {
    const queryInfo = getQueryStrings()
    const schoolId = queryInfo['tnId']
    let self = this
    try {
      const response = yield getTeachersLists(schoolId)
      if (response.data.retCode === 1) {
        self.teachersList = response.data.data || []
      } else {
        message.error(response.data.retMsg)
      }
    } catch (e) {
      console.log(e)
    }
  })
}

export default ModuleStore
