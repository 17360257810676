// function getApiPort() {
//   // var protocol = window.location.protocol

//   // var url = '//service.91chenzhi.cn'
//   // // var url = '//app11026.eapps.dingtalkcloud.com'
//   // // var url = '//service.91chenzhi.cn'
//   //   console.log(location.hostname.indexOf('91chenzhi.cn'))
//   // if (location.hostname.indexOf('test.91chenzhi.cn') != -1) {
//   //   console.log('1',url)
//   //   url = '//testservice.91chenzhi.cn'
//   // } else if (location.hostname.indexOf('dingding.91chenzhi.cn') != -1) {
//   //   console.log('2',url)
//   //   url = '//app11026.eapps.dingtalkcloud.com'
//   // } else if (location.hostname.indexOf('testxuejiajia.91chenzhi.cn') != -1) {
//   //   console.log('3',url)
//   //   url = '//testservice.91chenzhi.cn'
//   // } else if (location.hostname.indexOf('xuejiajia.91chenzhi.cn') != -1) {
//   //   console.log('4',url)
//   //   url = '//service.91chenzhi.cn'
//   // } else if (location.hostname.indexOf('91chenzhi.cn') != -1) {
//   //   console.log('5',url)
//   //   url = '//service.91chenzhi.cn'
//   // }


//   return "http://47.92.122.53:8091"
//   // return protocol + url;
// }


export const bucket = () => {
  let bucket = 'test91chenzhi'
  if (location.hostname.indexOf('test.91chenzhi.cn') != -1) {
    bucket = 'test91chenzhi'
  } else if (location.hostname.indexOf('dingding.91chenzhi.cn') != -1) {
    bucket = 'ding91chenzhi'
  } else if (location.hostname.indexOf('testxuejiajia.91chenzhi.cn') != -1) {
    bucket = 'test91chenzhi'
  } else if (location.hostname.indexOf('xuejiajia.91chenzhi.cn') != -1) {
    bucket = '91chhenzhi'
  } else if (location.hostname.indexOf('91chenzhi.cn') != -1) {
    bucket = '91chhenzhi'
  }
  return bucket
}



// export const API_ROOT = 'https://banpaiapi.shuxiaoyi.net';
export const API_ROOT = 'http://47.92.122.53:8091'
export const API_ROOTS = 'https://service.91chenzhi.cn'
