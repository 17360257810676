import { map } from 'lodash'
import CryptoJS from 'crypto-js'
import MD5 from 'js-md5'

/***
 *  .--,       .--,
 * ( (  \.---./  ) )
 *  '.__/o   o\__.'
 *     {=  ^  =}
 *      >  -  <
 *     /       \
 *    //       \\
 *   //|   .   |\\
 *   "'\       /'"_.-~^`'-.
 *      \  _  /--'         `
 *    ___)( )(___
 *   (((__) (__)))    高山仰止,景行行止.虽不能至,心向往之。
 */

//获取所有的参数（通过对象的形式返回）
export const getQueryStrings = () => {
  let queryData = {}
  if (window.__MICRO_APP_ENVIRONMENT__) {
    queryData = window.microApp?.getData() || {}
    return queryData
  }

  const hrefUrl = location.href
  const url = hrefUrl.split('?')[1] || ''
  let theRequest = {}
  if (url) {
    let strs = url.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(
        strs[i].split('=')[1]
      )
    }
  }
  return theRequest
}

// 转化时间
export const dateFormatHandle = (dataOrigin, callback) => {
  return map(dataOrigin, items =>
    map(items, item => callback(item).format('YYYY-MM-DD HH:mm:ss')).join('|')
  )
}
// 校验Form表单
export const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => {
    return fieldsError[field]
  })
}

// 页面路由跳转

export const linkToCenterProject = () => {
  let url = ''
  if (parent !== window) {
    try {
      url = parent.location.href
    } catch (e) {
      url = document.referrer
    }
  }
  // window.parent.location.href = url += '#' + this.state.setUrl
  window.parent.location.href = url += '#'
}

/**
 * 格式化考勤时段
 *
 * @param primeval = object
 * @periodType typeof number
 * @periodCode typeof number
 * @periodId typeof number
 */
export const formatPeriodLists = primeval => {
  let result = []
  if (primeval && primeval.length > 0) {
    primeval.map(item => {
      const { periodType, periodCode, periodId, periodName } = item
      const periodTypeLabel = periodType === 1 ? '上学' : '放学'
      const periodCodeLabel = () => {
        if (periodCode === 1) {
          return '上午'
        } else if (periodCode === 2) {
          return '下午'
        } else if (periodCode === 3) {
          return '晚上'
        } else {
          return ''
        }
      }
      const hasData = result.find(
        item => item.value === `${periodId}-${periodType}`
      )
      if (!hasData) {
        result.push({
          label: periodName
            ? `${periodName}${periodTypeLabel}`
            : `${periodCodeLabel()}${periodTypeLabel}`,
          value: `${periodId}-${periodType}`
        })
      }
    })
  }
  return result
}

/*
 * 打开新窗口
 * */

export const openNewWindow = uri => {
  if (uri) {
    window.open(uri)
  }
}

/**
 * 获取元素至浏览器顶部的高度
 */

export function getElementTop(element) {
  let actualTop = element.offsetTop
  let current = element.offsetParent

  while (current !== null) {
    actualTop += current.offsetTop
    current = current.offsetParent
  }

  return actualTop
}

/**
 * 处理长时段转换成月-日的形式
 */

export function timeToMonthAndDay(time) {
  const date = new Date(time)
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  return MOnth + Day
}

/**
 * 处理长时段转换成时-分-秒
 */

export function formatDuring(mss) {
  // const days = parseInt(mss / (1000 * 60 * 60 * 24));
  const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = (mss % (1000 * 60)) / 1000
  return (
    (hours > 0 ? hours + ' 小时 ' : '') +
    (minutes > 0 ? minutes + ' 分钟 ' : '') +
    seconds +
    '秒'
  )
}

/**
 * 处理长时段转换成月-日-小时-分钟的形式
 */

export function timeFormat(time) {
  time = parseInt(time)
  const date = new Date(time)
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const Hours =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const Miuntes =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return MOnth + Day + Hours + Miuntes + seconds
}

/**
 * 处理长时段转换成年-月-日-小时-分钟的形式
 */

export function timeFormatYear(time) {
  time = parseInt(time)
  const date = new Date(time)
  const Year = date.getFullYear() + '-'
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day =
    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const Hours =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const Miuntes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  // const seconds =
  //   date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Year + MOnth + Day + Hours + Miuntes
}

/**
 * 处理长时段转换成年-月-日的形式
 */

export function timeFormatDay(time) {
  time = parseInt(time)
  const date = new Date(time)
  const Year = date.getFullYear() + '-'
  const MOnth =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const Day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // const seconds =
  //   date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Year + MOnth + Day
}

/**
 * 处理长时段转换成年-月-日的形式
 */

export function timeFormatDayStr(time) {
  time = parseInt(time)
  const date = new Date(time)
  const Year = date.getFullYear()
  const MOnth =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const Day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // const seconds =
  //   date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Year + MOnth + Day
}

/**
 * 将数字装换成汉字
 */

export function digitalConversion(num) {
  const arr1 = new Array(
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九'
  )
  const arr2 = new Array(
    '',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿'
  ) //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零'
  }
  let english = num.toString().split('')
  let result = ''
  for (let i = 0; i < english.length; i++) {
    let des_i = english.length - 1 - i //倒序排列设值
    result = arr2[i] + result
    let arr1_index = english[des_i]
    result = arr1[arr1_index] + result
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  //合并中间多个零为一个零
  result = result.replace(/零+/g, '零')
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿')
  //移除末尾的零
  result = result.replace(/零+$/, '')
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, '十')
  return result
}

// aes解密
export const decrypt = (encryptedBase64Str, CRYPTOJSKEY) => {
  CRYPTOJSKEY = MD5(CRYPTOJSKEY).toUpperCase()
  console.log('CRYPTOJSKEY :>> ', CRYPTOJSKEY)
  var vals = encryptedBase64Str.replace(/\\-/g, '+').replace(/_/g, '/')
  var options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }
  var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY)
  var decryptedData = CryptoJS.AES.decrypt(vals, key, options)
  var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
}
