import { action, observable } from 'mobx'
import { cloneDeep } from 'lodash'
import { message } from 'antd'

class SubstanceStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }
  @observable currentStudentInfo = {}
  @observable checkedCandidateLists = []
  @observable fileLists = {
    imagesLists: [],
    videoLists: []
  }
  // action
  @action
  saveCurrentStudent = newValue => {
    this.currentStudentInfo = newValue
  }
  @action
  saveCandidateLists = newValues => {
    this.checkedCandidateLists = newValues
  }
  @action
  uploadFileListsHandle = file => {
    console.log(file)
    const { imagesLists, videoLists } = this.fileLists
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (isJpgOrPng) {
      imagesLists && imagesLists.length < 20
        ? (this.fileLists.imagesLists = [...imagesLists, file])
        : message.error('最多上传20张图片')
    } else {
      videoLists && videoLists.length < 10
        ? (this.fileLists.videoLists = [...videoLists, file])
        : message.error('最多上传10个视频')
    }
  }

  @action
  removeFileListsHandle = file => {
    const dataBase = cloneDeep(this.fileLists)
    const type = /image/g.test(file.type) ? 'imagesLists' : 'videoLists'
    if (type === 'imagesLists') {
      dataBase.imagesLists = dataBase.imagesLists.filter(
        item => item.uid !== file.uid
      )
    } else {
      dataBase.videoLists = dataBase.videoLists.filter(
        item => item.uid !== file.uid
      )
    }
    this.fileLists = dataBase
  }
  @action
  resetDataEmpty = () => {
    this.fileLists = {
      imagesLists: [],
      videoLists: []
    }
  }
}

export default SubstanceStore
